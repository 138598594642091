import posthog from 'posthog-js'

const PH_KEY_TEST = 'phc_dBoZ6kLDJU3EJt0gJ9d3j3FY2zOGAuKBNKGidI8bZW6'
const PH_STAGE_KEY = 'phc_PHA5AsNOoanobCvhw8EnttLbnBRYmFPVS0VrtMbZk02'
const PH_PROD_KEY = 'phc_s27wJj2SjSUyCAbMWxMOIXNLVbCDcNZFGc2NdKVvY32'

const POSTHOG_PROJECT_IDS = {
  'local': null,
  'dev': PH_KEY_TEST,
  'stage': PH_STAGE_KEY,
  'prod': PH_PROD_KEY
}

export default {
  data () {
    return {
      phclient: null
    }
  },

  methods: {
    /**
     * Initialize the darkly client
     */
    posthogInit ({ identity, disableSessionRecording, onFeatureFlags }) {
      if (this.nonUserMode) return
      if (this.phclient !== null) return

      const pid = POSTHOG_PROJECT_IDS[process.env.VUE_APP_CAPLENA_ENV]

      console.log('ph-init')

      if (onFeatureFlags) posthog.onFeatureFlags(onFeatureFlags)

      if (pid) {
        this.phclient = posthog.init(pid, {
          api_host: 'https://ph.caplena.com',
          ui_host: 'https://eu.posthog.com',
          capture_pageviews: true, // we set it to true, as we only initialize the manual pageview sending in router *after* posthog has been initialized
          disable_session_recording: disableSessionRecording,
          session_recording: {
            maskAllInputs: false,
            maskInputOptions: {
              password: true
            }
          }
        })
        if (identity) this.posthogIdentify(identity)
      } else if (onFeatureFlags) onFeatureFlags()
    },

    posthogIdentify (identity) {
      if (this.phclient) this.phclient.identify(identity)
    },

    posthogCapture (event, properties) {
      if (this.phclient) this.phclient.capture(event, properties)
    },

    posthogReset () {
      if (this.phclient) this.phclient.reset()
    },

    posthogFlagEnabled (flag, defaultValue) {
      if (this.phclient === null) return defaultValue
      else return this.phclient.isFeatureEnabled(flag)
    },

    posthogSetPersonPropertiesForFlags (properties) {
      this.phclient.setPersonPropertiesForFlags(properties)
    }
  }
}
